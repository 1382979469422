<template>
  <div class="operationsmanage-container">
    <div class="header-button">
      <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">新增计划</el-button>
      <el-button class="button" type="success" @click="PlusSetUp" size="small">设置</el-button>
      <el-button class="button" type="primary" @click="PlusReportForm" size="small">数据报表</el-button>
    </div>

    <el-table
        :data="list"
        size="small"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="name"
          label="计划名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="day"
          label="有效时间">
      </el-table-column>
      <el-table-column
          align="center"
          prop="price"
          label="销售价格">
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </div>
</template>
<script>
import Edit from './components/Edit'

export default {
  components: {Edit},
  props: [],
  data() {
    return {
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      current: 0,
      list: [{id: 1}, {id: 2}, {id: 3}],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 设置跳转
    PlusSetUp() {
      this.$router.push({
        path: 'PlusSetUp',
      })
    },
    // 跳转
    PlusReportForm() {
      this.$router.push({
        path: 'PlusReportForm',
      })
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
  }
}

</script>
<style>
.header-button {
  margin: 10px 0 10px 20px;
}
</style>
